import React, { useState, useEffect } from 'react'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'

const AddCourseForm = ({
    edit,
    defaultData,
    ...props
}) => {
    const [programList, setProgramList] = useState([])
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({})

    useEffect(() => {
        getProgramList()
    }, [])

    useEffect(() => {
        setFormData(defaultData ?? {})
    }, [defaultData])

    const clearFormData = () => {
        setFormData({})
    }

    const getProgramList = () => {
        ApiService.sendRequest('program-list').then(res => {
            setProgramList(res.data.map(item => {
                return {
                    key: item.id,
                    text: item.program_hierarchy_flat ? `${item.name} (${item.program_hierarchy_flat})` : item.name,
                    value: item.id
                }
            }))
        }).catch(err => {
        }).finally(() => {
        })
    }

    const submitForm = () => {
        let params = {
            ...formData,
        }
        setLoading(true)
        ApiService.sendRequest(`course/${edit ? 'update' : 'add'}`, params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleValueChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Modal {...props} size='small'>

            <Modal.Header>
                {edit ? 'Update Course' : 'Add Course'}
            </Modal.Header>

            <Modal.Content>

                <Form id='add-course-form' onSubmit={submitForm}>

                    <Form.Group>

                        <Form.Input
                            width='12'
                            label='Course Name'
                            value={formData.name ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('name', value)}
                        />

                        <Form.Input
                            label='Code'
                            value={formData.code ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('code', value)}
                        />

                    </Form.Group>

                    <Form.Field required>
                        <label>Category</label>
                        <Dropdown
                            required
                            placeholder='Select'
                            value={formData.program_id}
                            selection
                            search
                            options={programList}
                            onChange={(e, { value }) => handleValueChange('program_id', value)}
                        />
                    </Form.Field>

                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                {
                    edit
                        ? <Button
                            positive
                            icon='edit'
                            content='Update'
                            type='submit'
                            form='add-course-form'
                            disabled={loading}
                            loading={loading}
                        />
                        : <Button
                            primary
                            icon='add'
                            content='Add'
                            type='submit'
                            form='add-course-form'
                            disabled={loading}
                            loading={loading}
                        />
                }

            </Modal.Actions>

        </Modal>
    )
}

export default AddCourseForm