import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import Utils from '../../../utils/Utils'

const AddNoticeForm = ({
    edit,
    defaultData,
    ...props
}) => {

    const [programList, setProgramList] = useState([])

    const [programLoading, setProgramLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(defaultData ?? {})

    useEffect(() => {
        getProgramList()
        clearFormData()
    }, [])

    useEffect(() => {
        let data = {
            ...defaultData,
            program_ids: defaultData ? [defaultData.program_id] : []
        }
        
        setFormData(data ?? {})
    }, [defaultData])

    const clearFormData = () => {
        setFormData({})
    }

    const getProgramList = () => {
        let params = {
        }

        setProgramLoading(true)
        ApiService.sendRequest('base-program-list', params).then(res => {

            setProgramList(res.data.map(item => {
                return {
                    key: item.id,
                    text: item.program_hierarchy_flat ? `${item.name} (${item.program_hierarchy_flat})` : item.name,
                    value: item.id
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setProgramLoading(false)
        })
    }

    const submitForm = () => {
        if (edit) {
            updateNotice()
        } else {
            sendNotice()
        }
    }

    const sendNotice = () => {
        let params = {
            ...formData,
        }
        setLoading(true)
        ApiService.sendRequest('notice/add', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const updateNotice = () => {
        let params = {
            ...formData,
        }
        setLoading(true)
        ApiService.sendRequest('notice/update', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleValueChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Modal {...props} size='small'>

            <Modal.Header>{edit ? 'Edit Notice' : 'Send Notice'}</Modal.Header>

            <Modal.Content>

                <Form id='add-notice-form' onSubmit={submitForm}>

                    <Form.Field required disabled={edit}>
                        <label>Program</label>
                        <Dropdown
                            label='Program'
                            placeholder='Select'
                            value={formData.program_ids}
                            selection
                            search
                            multiple
                            disabled={programLoading}
                            loading={programLoading}
                            options={programList}
                            onChange={(e, { value }) => handleValueChange('program_ids', value)}
                        />
                    </Form.Field>

                    <Form.Input
                        label='Title'
                        value={formData.title ?? ''}
                        required
                        onChange={(e, { value }) => handleValueChange('title', value)}
                    />

                    <Form.TextArea
                        label='Message'
                        value={formData.text ?? ''}
                        required
                        placeholder=''
                        onChange={(e, { value }) => handleValueChange('text', value)}
                    />
                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                {
                    edit ? (
                        <Button
                            positive
                            icon='edit'
                            content='Update'
                            type='submit'
                            form='add-notice-form'
                            disabled={loading}
                            loading={loading}
                        />
                    ) : (
                        <Button
                            primary
                            icon='send'
                            content='Send'
                            type='submit'
                            form='add-notice-form'
                            disabled={loading}
                            loading={loading}
                        />
                    )
                }

            </Modal.Actions>

        </Modal>
    )
}

export default AddNoticeForm
