import React from 'react'
import { useState } from 'react'
import { Modal, Button, Form } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'

const AddTeacherForm = ({
    ...props
}) => {

    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState('')

    const submitForm = () => {
        let params = {
            email: email
        }
        setLoading(true)
        ApiService.sendRequest('teacher/register-email', params).then(res => {
            alert(res.message)
            setEmail('')
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal {...props} size='small'>

            <Modal.Header>Register Teacher Email</Modal.Header>

            <Modal.Content>

                <Form id='add-teacher-form' onSubmit={submitForm}>

                    <Form.Input
                        value={email}
                        label='Email Address'
                        type='email'
                        required
                        onChange={(e, { value }) => setEmail(value)}
                    />

                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                <Button
                    primary
                    icon='add'
                    content='Add'
                    type='submit'
                    form='add-teacher-form'
                    disabled={loading}
                    loading={loading}
                />
            </Modal.Actions>

        </Modal>
    )
}

export default AddTeacherForm
