import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import Utils from '../../../utils/Utils'

const EditTeacherForm = ({
    defaultData,
    ...props
}) => {

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(defaultData ?? {})

    useEffect(() => {
        clearFormData()
    }, [])

    useEffect(() => {
        let data = {
            ...defaultData,
            phone: Utils.cleanUpPhoneNumber(defaultData?.phone ?? ''),
        }
        setFormData(data ?? {})
    }, [defaultData])

    const clearFormData = () => {
        setFormData({})
    }

    const submitForm = () => {
        updateTeacher()
    }

    const updateTeacher = () => {
        let params = {
            ...formData,
            // phone: formData.phone.split(','),
        }
        setLoading(true)
        ApiService.sendRequest('teacher/complete-registration', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleValueChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Modal {...props} size='small'>

            <Modal.Header>{'Edit Teacher'}</Modal.Header>

            <Modal.Content>

                <Form id='add-student-form' onSubmit={submitForm}>

                    <Form.Group widths='equal'>
                        <Form.Input
                            label='First Name'
                            value={formData.first_name ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('first_name', value)}
                        />
                        <Form.Input
                            label='Middle Name'
                            value={formData.middle_name ?? ''}
                            onChange={(e, { value }) => handleValueChange('middle_name', value)}
                        />
                        <Form.Input
                            label='Last Name'
                            value={formData.last_name ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('last_name', value)}
                        />
                    </Form.Group>

                    <Form.Input
                        label='Email Address'
                        value={formData.email ?? ''}
                        type='email'
                        required
                        onChange={(e, { value }) => handleValueChange('email', value)}
                    />

                    <Form.Input
                        label='Contact Number'
                        value={formData.phone ?? ''}
                        required
                        placeholder=''
                        onChange={(e, { value }) => handleValueChange('phone', value)}
                    />
                    <Form.Group widths='equal'>

                        <Form.Input
                            value={formData.password}
                            label='Password'
                            onChange={(e, { value }) => handleValueChange('password', value)}
                        />

                        <Form.Input
                            value={formData.password_confirmation}
                            label='Confirm Password'
                            onChange={(e, { value }) => handleValueChange('password_confirmation', value)}
                        />
                    </Form.Group>
                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                <Button
                    positive
                    icon='edit'
                    content='Update'
                    type='submit'
                    form='add-student-form'
                    disabled={loading}
                    loading={loading}
                />
            </Modal.Actions>

        </Modal>
    )
}

export default EditTeacherForm
