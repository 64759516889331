class Utils {

    joinFullName(first, middle, last) {
        if (first && middle && last) {
            return `${first} ${middle} ${last}`
        } else if (first && last) {
            return `${first} ${last}`
        } else if (first) {
            return `${first}`
        } else {
            return ''
        }
    }

    cleanUpPhoneNumber(phone) {
        return phone?.replace(/"/g, '').replace(/\[/, '').replace(/\]/, '')
    }

}

export default new Utils()