import React, { useEffect, useState } from 'react'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'
import ApiService, { baseURL } from '../../../services/api/ApiService'
import { NepaliDatePicker } from "nepali-datepicker-reactjs"
import "nepali-datepicker-reactjs/dist/index.css"
import moment from 'moment'
import { ad2bs, bs2ad } from 'ad-bs-converter'

const TeacherAttendance = () => {

    const [downloading, setDownloading] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    useEffect(() => {
        let todaysDate = moment().format('YYYY-MM-DD').replace(/-/g, '/')
        let convertedDate = ad2bs(todaysDate)

        let { year, month, day } = convertedDate.en
        month = month < 10 ? `0${month}` : month

        let todaysDateNp = `${year}-${month}-${day}`
        let startOfMonth = `${year}-${month}-01`

        setStartDate(startOfMonth)
        setEndDate(todaysDateNp)
    }, [])

    const downloadData = () => {
        let params = {
            start_date: convertBs2Ad(startDate),
            end_date: convertBs2Ad(endDate)
        }

        setDownloading(true)
        ApiService.sendRequest('attendance/export-teacher', params).then(res => {
            let link = document.createElement('a')
            link.href = `${baseURL}${res.data.url}`
            link.download = res.data.file_name
            link.click()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDownloading(false)
        })
    }

    const convertBs2Ad = (bsDate) => {
        let convertedDate = bs2ad(bsDate.replace(/-/g, '/'))
        let { year, month, day } = convertedDate
        return `${year}-${month}-${day}`
    }

    return (
        <Segment>
            <Header as={'h3'}>Teacher Attendance</Header>
            <Form>
                {/* <Grid verticalAlign='bottom'> */}
                    {/* <Grid.Column width='5'> */}
                        <Form.Field required inline>
                            <label>Start Date</label>

                            <NepaliDatePicker
                                value={startDate}
                                options={{ calenderLocale: "en", valueLocale: "en" }}
                                onChange={(date) => setStartDate(date)}
                            />
                        </Form.Field>
                    {/* </Grid.Column> */}

                    {/* <Grid.Column width='5'> */}
                        <Form.Field required inline>
                            <label>End Date</label>

                            <NepaliDatePicker
                                todaySelect={false}
                                value={endDate}
                                options={{ calenderLocale: "en", valueLocale: "en" }}
                                onChange={(date) => setEndDate(date)}
                            />
                        </Form.Field>
                    {/* </Grid.Column> */}

                    {/* <Grid.Column width='6' textAlign='right'> */}

                        <Button
                            icon='download'
                            labelPosition='left'
                            content='Download'
                            positive
                            onClick={downloadData}
                            loading={downloading}
                        />

                    {/* </Grid.Column> */}
                {/* </Grid> */}

            </Form>

        </Segment >
    )
}

export default TeacherAttendance
