import './App.css';
import { Switch, Route } from 'react-router-dom';
import Login from './pages/login';
import Home from './pages/home/Home';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <div>
      <Switch>
        <Route path='/login' component={Login} />
        <Route path='/' component={Home} />
      </Switch>
    </div>
  );
}

export default App;
