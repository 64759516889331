import React, {  useEffect, useState } from 'react'
import { Button, Header, Table, Divider, Form, Modal, Dimmer, Loader, Dropdown } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import DateUtils from '../../../utils/DateUtils'
import AddRoutineForm from './Add'
import Utils from '../../../utils/Utils'

const Routine = () => {

    const [dataList, setDataList] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)

    const [addDataFormOpen, setAddDataFormOpen] = useState(false)
    const [editDataFormOpen, setEditDataFormOpen] = useState(false)

    const [dataToEdit, setDataToEdit] = useState({})

    const [filterWeekdayList, setFilterWeekdayList] = useState([])
    const [filterYearList, setFilterYearList] = useState([])
    const [filterCourseList, setFilterCourseList] = useState([])
    const [filterTeacherList, setFilterTeacherList] = useState([])
    const [selectedWeekday, setSelectedWeekday] = useState(new Date().getDay() + 1)
    const [selectedTeacher, setSelectedTeacher] = useState(null)
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [selectedYear, setSelectedYear] = useState(null)

    useEffect(() => {
        getDataList()
        getFilterParams()
    }, [])

    useEffect(() => {
        getDataList()
    }, [selectedWeekday, selectedCourse, selectedYear, selectedTeacher])

    const getDataList = () => {
        setDataLoading(true)

        let params = {
            weekday: selectedWeekday,
            year: selectedYear,
            courseId: selectedCourse,
            teacherId: selectedTeacher
        }
        ApiService.sendRequest('routine/get-list', params).then(res => {
            setDataList(res.data.map(item => {
                return {
                    ...item,
                    teacherFullName: Utils.joinFullName(item.teacher.first_name, item.teacher.middle_name, item.teacher.last_name),
                    fullCourseName: `${item.course.name} [${item.course.program_hierarchy_flat}]`
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDataLoading(false)
        })
    }

    const getFilterParams = () => {
        ApiService.sendRequest('routine/filter-params').then(res => {
            setFilterYearList(res.data.year_list.map(item => {
                return {
                    key: item,
                    text: item,
                    value: item
                }
            }))

            setFilterCourseList(res.data.course_list.map(item => {
                return {
                    key: item.id,
                    text: `${item.name} [${item.program_hierarchy_flat}]`,
                    value: item.id,
                    content: (
                        <Header content={item.name} subheader={item.program_hierarchy_flat} />
                    )
                }
            }))

            setFilterTeacherList(res.data.teacher_list.map(item => {
                return {
                    key: item.id,
                    text: Utils.joinFullName(item.first_name, item.middle_name, item.last_name),
                    value: item.id
                }
            }))

            setFilterWeekdayList(DateUtils.getWeekDayList().map((item, i) => {
                return {
                    key: i,
                    text: item,
                    value: i + 1
                }
            }))

        }).catch(err => {
        }).finally(() => {
        })
    }

    const DeleteButton = ({ data }) => {
        return <Modal
            size='tiny'
            trigger={<Button icon='trash' color='red' />}
            header={(<Header content='Confirm Delete?' />)}
            content={(
                <Modal.Content>
                    <span>Are you sure you want to delete routine for <b>{`${data.teacherFullName} ${data.fullCourseName}`}</b></span>
                </Modal.Content>)}
            actions={[
                {
                    key: 'delete', content: 'Delete', negative: true, onClick: () => deleteData(data), disabled: deleting,
                },
                'Cancel'
            ]}
        />
    }

    const deleteData = (data) => {
        let params = {
            'id': data.id
        }
        setDeleting(true)
        ApiService.sendRequest('routine/delete', params).then(res => {
            alert(res.message)
            getDataList()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDeleting(false)
        })
    }

    return (
        <div className='page-container'>
            <div className='page-header'>
                <Header size='huge'>Routine</Header>
                <Divider />

                <Form>
                    <Form.Group inline widths='equal'>

                        <Form.Field width='4'>
                            <label>Filter</label>
                            <Dropdown
                                value={selectedWeekday}
                                selection
                                clearable
                                labeled
                                search
                                options={filterWeekdayList}
                                placeholder='Weekday'
                                onChange={(e, { value }) => setSelectedWeekday(value)}
                            />
                        </Form.Field>

                        <Form.Field width='3'>
                            <Dropdown
                                value={selectedTeacher}
                                selection
                                search
                                clearable
                                options={filterTeacherList}
                                placeholder='Teacher'
                                onChange={(e, { value }) => setSelectedTeacher(value)}
                            />
                        </Form.Field>

                        <Form.Field width='5'>
                            <Dropdown
                                style={{ width: 360 }}
                                value={selectedCourse}
                                selection
                                search
                                clearable
                                options={filterCourseList}
                                placeholder='Course'
                                onChange={(e, { value }) => setSelectedCourse(value)}
                            />
                        </Form.Field>

                        <Form.Field >
                            <Dropdown
                                style={{ width: 100 }}
                                value={selectedYear}
                                selection
                                clearable
                                labeled
                                search
                                options={filterYearList}
                                placeholder='Year'
                                onChange={(e, { value }) => setSelectedYear(value)}
                            />
                        </Form.Field>



                        <Button
                            icon='add'
                            labelPosition='left'
                            content='Add'
                            primary
                            onClick={() => setAddDataFormOpen(true)}
                            disabled={dataLoading}
                            loading={dataLoading}
                        />
                    </Form.Group>
                </Form>
            </div>

            <Table compact='very' className='data-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SN</Table.HeaderCell>
                        <Table.HeaderCell>Weekday</Table.HeaderCell>
                        <Table.HeaderCell>Teacher Name</Table.HeaderCell>
                        <Table.HeaderCell>Start Time</Table.HeaderCell>
                        <Table.HeaderCell>End Time</Table.HeaderCell>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Course</Table.HeaderCell>
                        <Table.HeaderCell>Room No.</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        dataList.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{DateUtils.getWeekDay(item.weekday)}</Table.Cell>
                                <Table.Cell>{item.teacherFullName}</Table.Cell>
                                <Table.Cell>{item.start_time}</Table.Cell>
                                <Table.Cell>{item.end_time}</Table.Cell>
                                <Table.Cell>{item.year}</Table.Cell>
                                <Table.Cell>{item.fullCourseName}</Table.Cell>
                                <Table.Cell>{item.room_no}</Table.Cell>
                                <Table.Cell>
                                    <Button.Group size='tiny'>
                                        <Button icon='edit' positive title='Edit' onClick={() => {
                                            setDataToEdit(item)
                                            setEditDataFormOpen(true)
                                        }} />
                                        <DeleteButton data={item} title='Delete' />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>

            </Table>

            {
                dataLoading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )
            }

            <AddRoutineForm
                open={addDataFormOpen}
                onClose={() => {
                    setAddDataFormOpen(false)
                    getDataList()
                }}
            />

            <AddRoutineForm
                edit={true}
                defaultData={dataToEdit}
                open={editDataFormOpen}
                onClose={() => {
                    setEditDataFormOpen(false)
                    getDataList()
                }}
            />

        </div>
    )
}

export default Routine
