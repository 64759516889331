import axios from "axios";

// git remote add live https://keshabghimire@brooklyn.ghimirekeshab.com.np/plesk-git/brooklyn.git

// export const baseURL = 'http://localhost:8000'
// export const baseURL = 'http://attendance-application-api.herokuapp.com'
// export const baseURL = 'https://attendance-application-api.herokuapp.com'
// export const baseURL = 'https://attendance-api.padhnekotha.com.np'
export const baseURL = 'https://brooklyn-api.ghimirekeshab.com.np'

class ApiService {

    async sendRequest(url, params) {
        let config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
        };
        console.log('API REQUEST ===', url, params);

        return new Promise((resolve, reject) => {
            axios
                .post(`${baseURL}/api/${url}`, params, config)
                .then((response) => {
                    console.log('API RESPONSE ===', url, response.data);
                    if (response.data.success) {
                        resolve(response.data)
                    } else {
                        if (response.data.code === 100) {
                            reject(response.data.error[Object.keys(response.data.error)[0]])
                        } else {
                            reject(response.data.message || response.data.error[Object.keys(response.data.error)[0]])
                        }
                    }
                })
                .catch((error) => {
                    console.error('API ERROR ===', url, error.message);
                    reject(error.message)
                });
        })
    }

}

export default new ApiService()
