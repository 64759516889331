
class DatabaseService {

    saveToken(token) {
        localStorage.setItem('token', token)
    }

    getToken() {
        return localStorage.getItem('token')
    }

    clearToken() {
        this.saveToken(null)
    }
}

export default new DatabaseService()