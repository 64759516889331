import React, { useEffect } from 'react'
import { Switch, Route, Link, useHistory, NavLink } from 'react-router-dom'
import Program from './Program'
import Course from './Course'
import Teacher from './Teacher'
import Student from './Student'
import Notice from './Notice'
import { Icon, Sidebar, Container, Menu } from 'semantic-ui-react'
import Attendance from './Attendance'
import { useDispatch, useSelector } from 'react-redux'
import { setSessionExpired } from '../../services/redux/Actions'
import DatabaseService from '../../services/db/DatabaseService'
import Routine from './Routine'
import Book from './Book'

const Home = () => {

    const sessionExpired = useSelector(state => state.sessionExpired)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        checkSession()
    }, [])

    const checkSession = () => {
        let token = DatabaseService.getToken()
        console.log('Token', token);

        if (token == null || token == 'null') {
            console.log('Access token is missing. Logout');
            dispatch(setSessionExpired(true))
        } else {
            dispatch(setSessionExpired(false))
        }
    }

    useEffect(() => {
        if (sessionExpired) {
            console.log('Session expired');
            history.push('/login')
        }
    }, [sessionExpired])

    const logout = () => {
        DatabaseService.clearToken()
        dispatch(setSessionExpired(true))
    }

    return (
        <div>
            <Sidebar.Pushable>
                <Sidebar
                    style={{ display: 'flex', width: '16vw' }}
                    as={Menu}
                    direction='left'
                    animation='push'
                    vertical
                    inverted
                    visible={true}
                >

                    <Menu style={{ margin: '2em' }} vertical icon='labeled' inverted >
                        <Menu.Item
                            name='admin'>
                            <Icon name='user' />
                            Admin
                        </Menu.Item>
                    </Menu>


                    <Menu.Item as={NavLink} to='/' exact>
                        Home
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/routine'>
                        Routine
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/category'>
                        Category
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/course'>
                        Courses
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/teacher'>
                        Teachers
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/student'>
                        Students
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/book'>
                        Books
                    </Menu.Item>

                    <Menu.Item as={NavLink} to='/notice'>
                        Notices
                    </Menu.Item>

                    <div style={{ flex: 1 }} />

                    <Menu.Item position='right' onClick={logout} >
                        <Icon name='sign out' />
                        Logout
                    </Menu.Item>

                    <Menu.Item />

                </Sidebar>

                <Sidebar.Pusher className='app'>
                    <Container fluid>
                        <Switch>
                            <Route exact path='/' component={Attendance} />
                            <Route exact path='/routine' component={Routine} />
                            <Route exact path='/category' component={Program} />
                            <Route exact path='/course' component={Course} />
                            <Route exact path='/teacher' component={Teacher} />
                            <Route exact path='/student' component={Student} />
                            <Route exact path='/book' component={Book} />
                            <Route exact path='/notice' component={Notice} />
                        </Switch>
                    </Container>

                </Sidebar.Pusher>
            </Sidebar.Pushable>
        </div>
    )
}

export default Home
