import React, { useEffect, useState } from 'react'
import { Button, Header, Table, Divider, Form, Modal, Dimmer, Loader, Dropdown, Pagination } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import AddStudentForm from './Add'
import Utils from '../../../utils/Utils'

const Student = () => {

    const [studentList, setStudentList] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)

    const [addStudentFormOpen, setAddStudentFormOpen] = useState(false)
    const [editStudentFormOpen, setEditStudentFormOpen] = useState(false)

    const [editStudentData, setEditStudentData] = useState({})

    const [perPage, setPerPage] = useState(20)
    const [totalPage, setTotalPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [filterYearList, setFilterYearList] = useState([])
    const [filterProgramList, setFilterProgramList] = useState([])
    const [selectedYear, setSelectedYear] = useState(null)
    const [selectedProgram, setSelectedProgram] = useState(null)

    useEffect(() => {
        getStudentList()
        getStudentFilterParams()
    }, [])

    useEffect(() => {
        getStudentList()
    }, [currentPage, selectedProgram, selectedYear])

    const getStudentList = () => {
        setDataLoading(true)

        let params = {
            paginate: true,
            perPage: perPage,
            currentPage: currentPage,
            year: selectedYear,
            programId: selectedProgram
        }
        ApiService.sendRequest('student/get-list', params).then(res => {
            setTotalPage(res.data.last_page)

            setStudentList(res.data.data.map(item => {
                return {
                    ...item,
                    fullName: Utils.joinFullName(item.first_name, item.middle_name, item.last_name),
                    phoneNumber: Utils.cleanUpPhoneNumber(item.phone),
                    guardianPhoneNumber: Utils.cleanUpPhoneNumber(item.guardian_phone),
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDataLoading(false)
        })
    }

    const getStudentFilterParams = () => {
        ApiService.sendRequest('student/filter-params').then(res => {
            setFilterYearList(res.data.year_list.map(item => {
                return {
                    key: item,
                    text: item,
                    value: item
                }
            }))

            setFilterProgramList(res.data.program_list.map(item => {
                return {
                    key: item.id,
                    text: item.name,
                    value: item.id
                }
            }))
        }).catch(err => {
        }).finally(() => {
        })
    }

    const DeleteButton = ({ data }) => {
        return <Modal
            size='tiny'
            trigger={<Button icon='trash' color='red' />}
            header={(<Header content='Confirm Delete?' />)}
            content={`Are you sure you want to delete ${data.email}`}
            actions={[
                {
                    key: 'delete', content: 'Delete', negative: true, onClick: () => deleteStudent(data.email), disabled: deleting,
                },
                'Cancel'
            ]}
        />
    }

    const deleteStudent = (email) => {
        let params = {
            'email': email
        }
        setDeleting(true)
        ApiService.sendRequest('student/delete', params).then(res => {
            alert(res.message)
            getStudentList()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDeleting(false)
        })
    }

    return (
        <div className='page-container'>
            <div className='page-header'>
                <Header size='huge'>Students</Header>
                <Divider />

                <Form>
                    <Form.Group inline>

                        <Pagination
                            width='16'
                            defaultActivePage={currentPage}
                            firstItem={null}
                            lastItem={null}
                            totalPages={totalPage}
                            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
                        />

                        <Form.Field width='4' style={{ marginLeft: 24 }}>
                            <label>Filter</label>
                            <Dropdown
                                style={{ width: 100 }}
                                value={selectedYear}
                                selection
                                clearable
                                labeled
                                search
                                options={filterYearList}
                                placeholder='Year'
                                onChange={(e, { value }) => setSelectedYear(value)}
                            />
                        </Form.Field>

                        <Form.Field width='8'>
                            <Dropdown
                                style={{ width: 360 }}
                                value={selectedProgram}
                                selection
                                search
                                clearable
                                options={filterProgramList}
                                placeholder='Program'
                                onChange={(e, { value }) => setSelectedProgram(value)}
                            />
                        </Form.Field>


                        <Button
                            icon='add'
                            labelPosition='left'
                            content='Add'
                            primary
                            onClick={() => setAddStudentFormOpen(true)}
                            disabled={dataLoading}
                            loading={dataLoading}
                        />
                    </Form.Group>
                </Form>
            </div>

            <Table compact='very' className='data-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SN</Table.HeaderCell>
                        <Table.HeaderCell>Year</Table.HeaderCell>
                        <Table.HeaderCell>Program</Table.HeaderCell>
                        <Table.HeaderCell>Student Name</Table.HeaderCell>
                        <Table.HeaderCell>Symbol Number</Table.HeaderCell>
                        <Table.HeaderCell>Registration Number</Table.HeaderCell>
                        <Table.HeaderCell>Guardian Contact Number</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        studentList.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{(currentPage - 1) * perPage + i + 1}</Table.Cell>
                                <Table.Cell>{item.year}</Table.Cell>
                                <Table.Cell>{item.program.code}</Table.Cell>
                                <Table.Cell>{item.fullName}</Table.Cell>
                                <Table.Cell>{item.symbol_no}</Table.Cell>
                                <Table.Cell>{item.reg_no}</Table.Cell>
                                <Table.Cell>{item.guardianPhoneNumber}</Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>
                                    <Button.Group size='tiny'>
                                        <Button icon='edit' positive title='Edit' onClick={() => {
                                            setEditStudentData(item)
                                            setEditStudentFormOpen(true)
                                        }} />
                                        <DeleteButton data={item} title='Delete' />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>

            </Table>

            {
                dataLoading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )
            }

            <AddStudentForm
                open={addStudentFormOpen}
                onClose={() => {
                    setAddStudentFormOpen(false)
                    getStudentList()
                }}
            />

            <AddStudentForm
                edit={true}
                defaultData={editStudentData}
                open={editStudentFormOpen}
                onClose={() => {
                    setEditStudentFormOpen(false)
                    getStudentList()
                }}
            />

        </div>
    )
}

export default Student
