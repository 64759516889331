import React, { useEffect, useState } from 'react'
import { Button, Header, Table, Divider, Grid, Modal, Dimmer, Loader } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import Utils from '../../../utils/Utils'
import AddTeacherForm from './Add'
import EditTeacherForm from './Edit'

const Teacher = () => {

    const [teacherList, setTeacherList] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)

    const [addTeacherFormOpen, setAddTeacherFormOpen] = useState(false)
    const [editTeacherFormOpen, setEditTeacherFormOpen] = useState(false)

    const [editTeacherData, setEditTeacherData] = useState({})

    useEffect(() => {
        getTeacherList()
    }, [])

    const getTeacherList = () => {
        setDataLoading(true)

        let params = {}
        ApiService.sendRequest('teacher/get-list', params).then(res => {
            setTeacherList(res.data.map(item => {
                return {
                    ...item,
                    fullName: Utils.joinFullName(item.first_name, item.middle_name, item.last_name),
                    phone: Utils.cleanUpPhoneNumber(item.phone)
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDataLoading(false)
        })
    }

    const DeleteButton = ({ data }) => {
        return <Modal
            size='tiny'
            trigger={<Button icon='trash' color='red' title='Delete' />}
            header={(<Header content='Confirm Delete?' />)}
            content={`Are you sure you want to delete ${data.email}`}
            actions={[
                {
                    key: 'delete', content: 'Delete', negative: true, onClick: () => deleteTeacher(data.email), loading: deleting
                },
                'Cancel'
            ]}
        />
    }

    const deleteTeacher = (email) => {
        let params = {
            email: email
        }

        setDeleting(true)
        ApiService.sendRequest('teacher/delete', params).then(res => {
            alert(res.message)
            getTeacherList()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDeleting(false)
        })
    }

    return (
        <div className='page-container'>
            <div className='page-header'>
                <Header size='huge'>Teachers</Header>
                <Divider />

                <Grid columns='3' textAlign='right'>
                    <Grid.Row >
                        <Grid.Column width='3'>

                        </Grid.Column>

                        <Grid.Column width='9'>

                        </Grid.Column>

                        <Grid.Column width='4'>
                            <Button
                                icon='add'
                                labelPosition='left'
                                content='Add'
                                primary
                                onClick={() => setAddTeacherFormOpen(true)}
                                disabled={dataLoading}
                                loading={dataLoading}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </div>

            <Table compact='very' className='data-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SN</Table.HeaderCell>
                        <Table.HeaderCell>Teacher Name</Table.HeaderCell>
                        <Table.HeaderCell>Contact Number</Table.HeaderCell>
                        <Table.HeaderCell>Email Address</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        teacherList.map((item, i) => (
                            <Table.Row negative={item.status === 'A'} key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{item.fullName}</Table.Cell>
                                <Table.Cell>{item.phone}</Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>
                                    <Button.Group size='tiny'>
                                        <Button icon='edit' positive title='Edit' onClick={() => {
                                            setEditTeacherData(item)
                                            setEditTeacherFormOpen(true)
                                        }} />
                                        <DeleteButton data={item} />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>

            </Table>

            {
                dataLoading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )
            }

            <AddTeacherForm
                open={addTeacherFormOpen}
                onClose={() => {
                    setAddTeacherFormOpen(false)
                    getTeacherList()
                }}
            />

            <EditTeacherForm
                defaultData={editTeacherData}
                open={editTeacherFormOpen}
                onClose={() => {
                    setEditTeacherFormOpen(false)
                    getTeacherList()
                }}
            />
        </div>
    )
}

export default Teacher
