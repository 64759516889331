import { SET_AUTH_SESSION, SET_USER_DETAILS , SET_SESSION_EXPIRED} from './Actions'

export function authSession(state = null, action) {
    switch (action.type) {
        case SET_AUTH_SESSION:
            return state = action.payload;
        default:
            return state;
    }
};


export function userDetails(state = null, action) {
    switch (action.type) {
        case SET_USER_DETAILS:
            return state = action.payload;
        default:
            return state;
    }
};

export function sessionExpired(state = null, action) {
    switch (action.type) {
        case SET_SESSION_EXPIRED:
            return state = action.payload;
        default:
            return state;
    }
};

