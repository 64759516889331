import React, { forwardRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import DateUtils from '../../../utils/DateUtils'
import Utils from '../../../utils/Utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

const AddRoutineForm = ({
    edit,
    defaultData,
    ...props
}) => {

    const [courseList, setCourseList] = useState([])
    const [yearList, setYearList] = useState([])
    const [teacherList, setTeacherList] = useState([])
    const [weekdayList, setWeekdayList] = useState([])
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)

    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(defaultData ?? {})

    useEffect(() => {
        getWeekDayList()
        getYearList()
        getCourseList()
        getTeacherList()
        clearFormData()
    }, [])

    useEffect(() => {
        let data = { ...defaultData }
        setFormData(data ?? {})

        if (data?.start_time) {
            setStartTime(moment(data.start_time, 'hh:mm:ss').toDate())
        }

        if (data?.end_time) {
            setEndTime(moment(data.end_time, 'hh:mm:ss').toDate())
        }

    }, [defaultData])

    const clearFormData = () => {
        setFormData({})
    }

    const getWeekDayList = () => {
        setWeekdayList(DateUtils.getWeekDayList().map((item, i) => {
            return {
                key: i,
                text: item,
                value: i + 1
            }
        }))
    }

    const getYearList = () => {
        ApiService.sendRequest('student/filter-params').then(res => {
            setYearList(res.data.year_list.map(item => {
                return {
                    key: item,
                    text: item,
                    value: item
                }
            }))
        }).catch(err => {
        }).finally(() => {
        })
    }

    const getCourseList = () => {
        let params = {
        }
        ApiService.sendRequest('course-list', params).then(res => {
            setCourseList(res.data.map(item => {
                return {
                    key: item.id,
                    text: `${item.name} [${item.program_hierarchy_flat}]`,
                    value: item.id
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
        })
    }

    const getTeacherList = () => {
        let params = {
        }
        ApiService.sendRequest('teacher/get-list', params).then(res => {
            setTeacherList(res.data.map(item => {
                return {
                    key: item.id,
                    text: `${Utils.joinFullName(item.first_name, item.middle_name, item.last_name)}  (${item.email})`,
                    value: item.id
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
        })
    }

    const submitForm = () => {
        if (edit) {
            updateData()
        } else {
            addData()
        }
    }

    const addData = () => {
        let params = {
            ...formData,
            start_time: moment(startTime).format('HH:mm:ss'),
            end_time: moment(endTime).format('HH:mm:ss')
        }
        setLoading(true)
        ApiService.sendRequest('routine/add', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const updateData = () => {
        let params = {
            ...formData,
            start_time: moment(startTime).format('HH:mm:ss'),
            end_time: moment(endTime).format('HH:mm:ss')
        }
        setLoading(true)
        ApiService.sendRequest('routine/update', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleValueChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const StartTimePicker = forwardRef(({ value, onClick }, ref) => (
        <Form.Input
            required
            icon='clock'
            iconPosition='left'
            placeholder='Start Time'
            label='Start Time'
            value={value}
            onClick={onClick}
        />
    ))


    const EndTimePicker = forwardRef(({ value, onClick }, ref) => (
        <Form.Input
            required
            icon='clock'
            iconPosition='left'
            placeholder='End Time'
            label='End Time'
            value={value}
            onClick={onClick}
        />
    ))

    return (
        <Modal {...props} size='small'>

            <Modal.Header>{edit ? 'Edit Routine' : 'Add Routine'}</Modal.Header>

            <Modal.Content>

                <Form id='add-routine-form' onSubmit={submitForm}>

                    <Form.Group widths='equal'>
                        <Form.Field width='10' required>
                            <label>Course</label>
                            <Dropdown
                                required
                                placeholder='Select'
                                value={formData.course_id}
                                selection
                                search
                                options={courseList}
                                onChange={(e, { value }) => handleValueChange('course_id', value)}
                            />
                        </Form.Field>

                        <Form.Field width='6' required>
                            <label>Year</label>
                            <Dropdown
                                placeholder='Select'
                                value={formData.year}
                                selection
                                search
                                options={yearList}
                                onChange={(e, { value }) => handleValueChange('year', value)}
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field required>
                            <label>Teacher</label>
                            <Dropdown
                                placeholder='Select'
                                value={formData.teacher_id}
                                selection
                                search
                                options={teacherList}
                                onChange={(e, { value }) => handleValueChange('teacher_id', value)}
                            />
                        </Form.Field>

                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field required>
                            <label>Weekday</label>
                            <Dropdown
                                placeholder='Select'
                                value={formData.weekday || []}
                                selection
                                search
                                multiple
                                options={weekdayList}
                                onChange={(e, { value }) => handleValueChange('weekday', value)}
                            />
                        </Form.Field>

                        <DatePicker
                            selected={startTime}
                            showTimeInput
                            showTimeSelectOnly
                            timeInputLabel='Start Time'
                            customInput={<StartTimePicker />}
                            onChange={(date) => setStartTime(date)}
                            dateFormat="h:mm aa"
                        />

                        <DatePicker
                            selected={endTime}
                            showTimeInput
                            showTimeSelectOnly
                            timeInputLabel='End Time'
                            customInput={<EndTimePicker />}
                            onChange={date => setEndTime(date)}
                            dateFormat="h:mm aa"
                        />

                    </Form.Group>

                    <Form.Input
                        value={formData.room_no ?? ''}
                        label='Room Number'
                        required
                        onChange={(e, { value }) => handleValueChange('room_no', value)}
                    />

                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                {
                    edit ? (
                        <Button
                            positive
                            icon='edit'
                            content='Update'
                            type='submit'
                            form='add-routine-form'
                            disabled={loading}
                            loading={loading}
                        />
                    ) : (
                        <Button
                            primary
                            icon='add'
                            content='Add'
                            type='submit'
                            form='add-routine-form'
                            disabled={loading}
                            loading={loading}
                        />
                    )
                }

            </Modal.Actions>

        </Modal>
    )
}

export default AddRoutineForm
