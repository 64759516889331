import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Button, Form, Dropdown } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import Utils from '../../../utils/Utils'

const AddStudentForm = ({
    edit,
    defaultData,
    ...props
}) => {

    const [programList, setProgramList] = useState([])

    const [programLoading, setProgramLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(defaultData ?? {})

    useEffect(() => {
        getProgramList()
        clearFormData()
    }, [])

    useEffect(() => {
        let data = { 
            ...defaultData, 
            phone: Utils.cleanUpPhoneNumber(defaultData?.phone ?? ''),
            guardian_phone: Utils.cleanUpPhoneNumber(defaultData?.guardian_phone ?? '') 
        }
        setFormData(data ?? {})
    }, [defaultData])

    const clearFormData = () => {
        setFormData({})
    }

    const getProgramList = () => {
        let params = {
        }

        setProgramLoading(true)
        ApiService.sendRequest('base-program-list', params).then(res => {

            setProgramList(res.data.map(item => {
                return {
                    key: item.id,
                    text: item.program_hierarchy_flat ? `${item.name} (${item.program_hierarchy_flat})` : item.name,
                    value: item.id
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setProgramLoading(false)
        })
    }

    const submitForm = () => {
        if (edit) {
            updateStudent()
        } else {
            addStudent()
        }
    }

    const addStudent = () => {
        let params = {
            ...formData,
            phone: formData.phone.split(','),
            guardian_phone: formData.guardian_phone.split(',')
        }
        setLoading(true)
        ApiService.sendRequest('student/add', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const updateStudent = () => {
        let params = {
            ...formData,
            phone: formData.phone.split(','),
            guardian_phone: formData.guardian_phone.split(',')
        }
        setLoading(true)
        ApiService.sendRequest('student/update', params).then(res => {
            alert(res.message)
            clearFormData()
            props.onClose()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleValueChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Modal {...props} size='small'>

            <Modal.Header>{edit ? 'Edit Student' : 'Add Student'}</Modal.Header>

            <Modal.Content>

                <Form id='add-student-form' onSubmit={submitForm}>

                    <Form.Group widths='equal'>
                        <Form.Input
                            label='First Name'
                            value={formData.first_name ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('first_name', value)}
                        />
                        <Form.Input
                            label='Middle Name'
                            value={formData.middle_name ?? ''}
                            onChange={(e, { value }) => handleValueChange('middle_name', value)}
                        />
                        <Form.Input
                            label='Last Name'
                            value={formData.last_name ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('last_name', value)}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>
                        <Form.Input
                            label='Symbol Number'
                            value={formData.symbol_no ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('symbol_no', value)}
                        />

                        <Form.Input
                            label='Registration Number'
                            value={formData.reg_no ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('reg_no', value)}
                        />
                    </Form.Group>

                    <Form.Group widths='equal'>

                        <Form.Field width='10' required>
                            <label>Program</label>
                            <Dropdown
                                label='Program'
                                placeholder='Select'
                                value={formData.program_id}
                                selection
                                search
                                disabled={programLoading}
                                loading={programLoading}
                                options={programList}
                                onChange={(e, { value }) => handleValueChange('program_id', value)}
                            />
                        </Form.Field>

                        <Form.Input
                            width='4'
                            label='Year'
                            value={formData.year ?? ''}
                            required
                            onChange={(e, { value }) => handleValueChange('year', value)}
                        />

                    </Form.Group>

                    <Form.Input
                        label='Email Address'
                        value={formData.email ?? ''}
                        type='email'
                        required
                        onChange={(e, { value }) => handleValueChange('email', value)}
                    />

                    <Form.Input
                        label='Contact Number'
                        value={formData.phone ?? ''}
                        required
                        placeholder='If multiple separate by comma. Eg. 9800000000,9800000001'
                        onChange={(e, { value }) => handleValueChange('phone', value)}
                    />

                    <Form.Input
                        label='Guardian Contact Number'
                        value={formData.guardian_phone ?? ''}
                        required
                        placeholder='If multiple separate by comma. Eg. 9800000000,9800000001'
                        onChange={(e, { value }) => handleValueChange('guardian_phone', value)}
                    />

                    <Form.Input
                        value={formData.address}
                        label='Address'
                        onChange={(e, { value }) => handleValueChange('address', value)}
                    />

                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                {
                    edit ? (
                        <Button
                            positive
                            icon='edit'
                            content='Update'
                            type='submit'
                            form='add-student-form'
                            disabled={loading}
                            loading={loading}
                        />
                    ) : (
                        <Button
                            primary
                            icon='add'
                            content='Add'
                            type='submit'
                            form='add-student-form'
                            disabled={loading}
                            loading={loading}
                        />
                    )
                }

            </Modal.Actions>

        </Modal>
    )
}

export default AddStudentForm
