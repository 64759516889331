import React, { forwardRef, useEffect, useState } from 'react'
import { Button, Header, Table, Divider, Grid, Form, Dropdown, Dimmer, Loader } from 'semantic-ui-react'
import ApiService, { baseURL } from '../../../services/api/ApiService'
import DateUtils from '../../../utils/DateUtils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Utils from '../../../utils/Utils'
import TeacherAttendance from './TeacherAttendance'
import StudentTeacherAttendance from './StudentTeacherAttendance'

const Attendance = () => {

    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedCourse, setSelectedCourse] = useState(null) // `${course_id}-${year}`, will be splitted to get individual values

    const [metaData, setMetaData] = useState([])
    const [coursesDropdownOptions, setCoursesDropdownOptions] = useState([])
    const [attendanceData, setAttendanceData] = useState([])

    const [attendanceDataLoading, setAttendanceDataLoading] = useState(true)
    const [prepareFileLoading, setPrepareFileLoading] = useState(false)

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (
        <Form.Input
            icon='calendar'
            iconPosition='left'
            placeholder='Date'
            value={value}
            onClick={onClick}
        />
    ))

    useEffect(() => {
        getAttendanceMeta()
    }, [selectedDate])

    useEffect(() => {
        if (selectedDate && selectedCourse)
            getAttendanceData()
    }, [selectedDate, selectedCourse])

    const getAttendanceMeta = () => {
        let params = {
            date: DateUtils.formatDate(selectedDate)
        }

        setAttendanceDataLoading(true)
        ApiService.sendRequest('attendance/get-submitted-list', params).then(res => {
            setMetaData(res.data)
            setCoursesDropdownOptions(res.data.map((item, i) => {
                let courseName = item.course.name
                let programHierarchy = item.course.program_hierarchy.reduce((acc, item) => acc + ' / ' + item, '')
                programHierarchy = programHierarchy.substring(2)
                let year = item.student.year

                return {
                    key: i,
                    text: `${courseName} ${programHierarchy} ${year}`,
                    value: `${item.course.id}-${year}`,
                    content: (
                        <Header content={`${courseName}`} subheader={`${programHierarchy} ${year}`} />
                    )
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setAttendanceDataLoading(false)
        })
    }

    const getAttendanceData = () => {

        let params = {
            date: DateUtils.formatDate(selectedDate),
            course_id: selectedCourse?.split('-')[0],
            year: selectedCourse?.split('-')[1]
        }

        setAttendanceDataLoading(true)
        ApiService.sendRequest('attendance/get-list', params).then(res => {
            setAttendanceData(res.data.map((item, i) => {
                return {
                    ...item,
                    fullName: Utils.joinFullName(item.first_name, item.middle_name, item.last_name),
                    phoneNumber: Utils.cleanUpPhoneNumber(item.phone)
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setAttendanceDataLoading(false)
        })
    }

    const handleDateSelection = (date) => {
        setSelectedDate(date)
    }

    const hanldeCourseSelection = (e, { value }) => {
        setSelectedCourse(value)
    }

    const downloadData = () => {
        let coursesData = metaData.map(item => {
            return { course_id: item.course.id, year: item.student.year }
        })
        if (coursesData.length == 0) {
            alert('No data available')
            return
        }
        let params = {
            date: DateUtils.formatDate(selectedDate),
            courses_data: coursesData
        }

        setPrepareFileLoading(true)
        ApiService.sendRequest('attendance/export', params).then(res => {
            let link = document.createElement('a')
            link.href = `${baseURL}${res.data.url}`
            link.target = '_blank'
            link.download = res.data.file_name
            link.click()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setPrepareFileLoading(false)
        })
    }

    return (
        <div className='page-container'>
            <div className='page-header'>

                <Header size='huge'>Attendance</Header>

                <Divider />

                <Grid columns='2'>
                    <Grid.Column>
                        <TeacherAttendance />
                    </Grid.Column>
                    <Grid.Column>
                        <StudentTeacherAttendance />
                    </Grid.Column>
                </Grid>


                <Divider />

                <Grid columns='3' textAlign='right'>
                    <Grid.Row >
                        <Grid.Column width='3'>
                            <DatePicker
                                selected={selectedDate}
                                onChange={date => handleDateSelection(date)}
                                maxDate={new Date()}
                                customInput={<DatePickerInput />}
                            />
                        </Grid.Column>

                        <Grid.Column width='9'>
                            <Dropdown
                                fluid
                                selection
                                search
                                options={coursesDropdownOptions}
                                placeholder='Select a course'
                                loading={attendanceDataLoading}
                                onChange={hanldeCourseSelection}
                            />
                        </Grid.Column>

                        <Grid.Column width='4'>
                            <Button
                                icon='download'
                                labelPosition='left'
                                content='Download'
                                positive
                                onClick={downloadData}
                                disabled={prepareFileLoading}
                                loading={prepareFileLoading}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>

            </div>

            <Table compact='very' className='data-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SN</Table.HeaderCell>
                        <Table.HeaderCell>Student Name</Table.HeaderCell>
                        <Table.HeaderCell>Symbol Number</Table.HeaderCell>
                        <Table.HeaderCell>Registration Number</Table.HeaderCell>
                        <Table.HeaderCell>Contact Number</Table.HeaderCell>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        attendanceData.map((item, i) => (
                            <Table.Row negative={item.status === 'A'} key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{item.fullName}</Table.Cell>
                                <Table.Cell>{item.symbol_no}</Table.Cell>
                                <Table.Cell>{item.reg_no}</Table.Cell>
                                <Table.Cell>{item.phoneNumber}</Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.status}</Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>

            </Table>

            {
                attendanceDataLoading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )
            }

        </div>
    )
}

export default Attendance
