import { createStore, combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { authSession, sessionExpired, userDetails } from './Reducers'

const rootReducer = combineReducers({
    authSession: authSession,
    userDetails: userDetails,
    sessionExpired: sessionExpired
})

const persistConfig = {
    'key': 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer)
let persistor = persistStore(store)

export { store, persistor }