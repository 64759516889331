import axios from 'axios'
import React, { forwardRef, useEffect, useState } from 'react'
import { Modal, Button, Form, Dropdown, Checkbox } from 'semantic-ui-react'
import FileInput from '../../../components/FileInput'
import ApiService, { baseURL } from '../../../services/api/ApiService'
import Utils from '../../../utils/Utils'

const AddBookForm = ({
    edit,
    defaultData,
    ...props
}) => {

    const [programList, setProgramList] = useState([])
    const [courseList, setCourseList] = useState([])
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(defaultData ?? {})

    useEffect(() => {
        getProgramList()
        getCourseList()
        clearFormData()
    }, [])

    useEffect(() => {
        let data = { ...defaultData }
        setFormData(data ?? {})
    }, [defaultData])

    const clearFormData = () => {
        setFormData({})
    }

    const getProgramList = () => {
        ApiService.sendRequest('program-list').then(res => {
            setProgramList(res.data.map(item => {
                return {
                    key: item.id,
                    text: item.program_hierarchy_flat ? `${item.name} (${item.program_hierarchy_flat})` : item.name,
                    value: item.id
                }
            }))
        }).catch(err => {
        }).finally(() => {
        })
    }

    const getCourseList = () => {
        let params = {
        }
        ApiService.sendRequest('course-list', params).then(res => {
            setCourseList(res.data.map(item => {
                return {
                    key: item.id,
                    text: `${item.name} [${item.program_hierarchy_flat}]`,
                    value: item.id
                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
        })
    }

    const submitForm = () => {
        let params = new FormData()
        Object.keys(formData).forEach(key => {
            if (formData[key]) {
                params.append(key, formData[key])
            }
            console.log(key, formData[key]);
        })
        setLoading(true)

        let url = edit ? 'book/update' : 'book/add'

        console.log('API REQUEST ===', url, params);
        axios.post(`${baseURL}/api/${url}`, params, {
            headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8; boundary=' + Math.random().toString().substr(2),
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            onUploadProgress: (e) => {
                let progress = parseInt(Math.round((e.loaded * 100) / e.total));
                console.log('Upload progress', progress);
            },
        }).then(response => {
            console.log('API RESPONSE ===', url, response.data);
            if (response.data.success) {
                alert(response.data.message)
                clearFormData()
                props.onClose()
            } else {
                if (response.data.code === 100) {
                    alert(response.data.error[Object.keys(response.data.error)[0]])
                } else {
                    alert(response.data.message || response.data.error[Object.keys(response.data.error)[0]])
                }
            }
        }).catch(error => {
            console.error('API ERROR ===', url, error.message);
            alert(error.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleValueChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <Modal {...props} size='small'>

            <Modal.Header>{edit ? 'Edit Book' : 'Add Book'}</Modal.Header>

            <Modal.Content>

                <Form id='add-book-form' onSubmit={submitForm}>

                    <Form.Group widths='equal'>

                        <Form.Input
                            value={formData.name ?? ''}
                            label='Name'
                            required
                            onChange={(e, { value }) => handleValueChange('name', value)}
                        />

                        <Form.Input
                            width='6'
                            required
                            value={formData.category ?? ''}
                            label='Category'
                            placeholder='Eg. Books, Notes'
                            onChange={(e, { value }) => handleValueChange('category', value)}
                        />

                    </Form.Group>

                    <Checkbox
                        toggle
                        label='Course Material'
                        checked={formData.isCourseBook}
                        onChange={(e, { checked }) => handleValueChange('isCourseBook', checked)}
                    />

                    <br />
                    <br />

                    {
                        formData.isCourseBook
                            ? (
                                <Form.Group widths='equal'>
                                    <Form.Field width='10'>
                                        <label>Course (Optional)</label>
                                        <Dropdown
                                            required
                                            placeholder='Select'
                                            value={formData.course_id}
                                            selection
                                            search
                                            clearable
                                            options={courseList}
                                            onChange={(e, { value }) => handleValueChange('course_id', value)}
                                        />
                                    </Form.Field>

                                </Form.Group>
                            ) : (

                                <Form.Group widths='equal'>
                                    <Form.Field width='10'>
                                        <label>Program (Optional)</label>
                                        <Dropdown
                                            required
                                            placeholder='Select'
                                            value={formData.program_id}
                                            selection
                                            search
                                            clearable
                                            options={programList}
                                            onChange={(e, { value }) => handleValueChange('program_id', value)}
                                        />
                                    </Form.Field>

                                </Form.Group>
                            )
                    }

                    <Form.Group widths='equal'>
                        <Form.Field width='4' required>
                            <label>Cover Image</label>
                            <FileInput
                                name='cover_image'
                                accept='.jpg,.jpeg,.png'
                                onValueChange={(name, value) => handleValueChange(name, value)}
                            />
                        </Form.Field>

                        <Form.Field width='4' required>
                            <label>PDF File</label>
                            <FileInput
                                name='file'
                                accept='.pdf'
                                onValueChange={(name, value) => handleValueChange(name, value)}
                            />
                        </Form.Field>
                    </Form.Group>

                    <br />

                </Form>

            </Modal.Content>

            <Modal.Actions>
                {
                    edit ? (
                        <Button
                            positive
                            icon='edit'
                            content='Update'
                            type='submit'
                            form='add-book-form'
                            disabled={loading}
                            loading={loading}
                        />
                    ) : (
                        <Button
                            primary
                            icon='add'
                            content='Add'
                            type='submit'
                            form='add-book-form'
                            disabled={loading}
                            loading={loading}
                        />
                    )
                }

            </Modal.Actions>

        </Modal>
    )
}

export default AddBookForm
