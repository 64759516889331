import React, { useEffect, useState } from 'react'
import { Button, Header, Table, Divider, Form, Modal, Dimmer, Loader, Dropdown, Image } from 'semantic-ui-react'
import ApiService, { baseURL } from '../../../services/api/ApiService'
import AddBookForm from './Add'

const Book = () => {

    const [dataList, setDataList] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)

    const [addDataFormOpen, setAddDataFormOpen] = useState(false)
    const [editDataFormOpen, setEditDataFormOpen] = useState(false)

    const [dataToEdit, setDataToEdit] = useState({})

    const [filterCourseList, setFilterCourseList] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)

    useEffect(() => {
        getDataList()
        getFilterParams()
    }, [])

    useEffect(() => {
        getDataList()
    }, [selectedCourse])

    const getDataList = () => {
        setDataLoading(true)

        let params = {
            course_id: selectedCourse,
        }
        ApiService.sendRequest('book/get-list', params).then(res => {
            setDataList(res.data)
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDataLoading(false)
        })
    }

    const getFilterParams = () => {
        ApiService.sendRequest('book/filter-params').then(res => {
            setFilterCourseList(res.data.course_list.map(item => {
                return {
                    key: item.id,
                    text: `${item.name} [${item.program_hierarchy_flat}]`,
                    value: item.id,
                    content: (
                        <Header content={item.name} subheader={item.program_hierarchy_flat} />
                    )
                }
            }))

        }).catch(err => {
        }).finally(() => {
        })
    }

    const DeleteButton = ({ data }) => {
        return <Modal
            size='tiny'
            trigger={<Button icon='trash' color='red' />}
            header={(<Header content='Confirm Delete?' />)}
            content={(
                <Modal.Content>
                    <span>Are you sure you want to delete <b>{`${data.name} ${data.program_hierarchy_flat ? data.program_hierarchy_flat : ' '}`}</b></span>
                </Modal.Content>)}
            actions={[
                {
                    key: 'delete', content: 'Delete', negative: true, onClick: () => deleteData(data), disabled: deleting,
                },
                'Cancel'
            ]}
        />
    }

    const deleteData = (data) => {
        let params = {
            'id': data.id
        }
        setDeleting(true)
        ApiService.sendRequest('book/delete', params).then(res => {
            alert(res.message)
            getDataList()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDeleting(false)
        })
    }

    return (
        <div className='page-container'>
            <div className='page-header'>
                <Header size='huge'>Books</Header>
                <Divider />

                <Form>
                    <Form.Group inline widths='equal'>

                        <Form.Field width='5'>
                            <label>Filter</label>

                            <Dropdown
                                style={{ width: 360 }}
                                value={selectedCourse}
                                selection
                                search
                                clearable
                                options={filterCourseList}
                                placeholder='Course'
                                clearable
                                onChange={(e, { value }) => setSelectedCourse(value)}
                            />
                        </Form.Field>

                        <Button
                            icon='add'
                            labelPosition='left'
                            content='Add'
                            primary
                            onClick={() => setAddDataFormOpen(true)}
                            disabled={dataLoading}
                            loading={dataLoading}
                        />
                    </Form.Group>
                </Form>
            </div>

            <Table compact='very' className='data-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SN</Table.HeaderCell>
                        <Table.HeaderCell>File</Table.HeaderCell>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Program</Table.HeaderCell>
                        <Table.HeaderCell>Course</Table.HeaderCell>
                        <Table.HeaderCell>Category</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        dataList.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>
                                    <Image
                                        width='100'
                                        as='a'
                                        href={`${baseURL}${item.file_url}`}
                                        target='blank'
                                        src={`${baseURL}${item.cover_image_url}`}
                                        rounded
                                    />
                                </Table.Cell>
                                <Table.Cell>{item.name}</Table.Cell>
                                <Table.Cell>
                                    {
                                        item.program?.name
                                            ? `${item.program?.name ?? ''} [${item.program_hierarchy_flat ? item.program_hierarchy_flat : ''}]`
                                            : ''
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {
                                        item.course?.name
                                            ? `${item.course?.name ?? ''} [${item.program_hierarchy_flat ? item.program_hierarchy_flat : ''}]`
                                            : ''
                                    }
                                </Table.Cell>
                                <Table.Cell>{item.category}</Table.Cell>
                                <Table.Cell>
                                    <Button.Group size='tiny'>
                                        <Button icon='edit' positive title='Edit' onClick={() => {
                                            setDataToEdit(item)
                                            setEditDataFormOpen(true)
                                        }} />
                                        <DeleteButton data={item} title='Delete' />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>

            </Table>

            {
                dataLoading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )
            }

            <AddBookForm
                open={addDataFormOpen}
                onClose={() => {
                    setAddDataFormOpen(false)
                    getDataList()
                }}
            />

            <AddBookForm
                edit={true}
                defaultData={dataToEdit}
                open={editDataFormOpen}
                onClose={() => {
                    setEditDataFormOpen(false)
                    getDataList()
                }}
            />

        </div>
    )
}

export default Book
