import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { Header, Grid, Form, Button, Message } from 'semantic-ui-react'
import ApiService from '../../services/api/ApiService'
import DatabaseService from '../../services/db/DatabaseService'
import { setSessionExpired } from '../../services/redux/Actions'

const Login = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [errorMessage, setErrorMessage] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const history = useHistory()
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        setErrorMessage(null)
        setIsLoading(true)

        let params = {
            email: email,
            password: password
        }
        dispatch(setSessionExpired(false))

        ApiService.sendRequest('auth/login', params).then(res => {
            DatabaseService.saveToken(res.data.access_token)
            history.push('/')
        }).catch(err => {
            setErrorMessage(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <Grid style={{ height: '100vh' }} centered verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 300 }}>

                <Header textAlign='center'>Login</Header>
                <Form onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Email</label>
                        <input
                            placeholder='Email'
                            value={email}
                            type='email'
                            required
                            onChange={e => setEmail(e.target.value)}
                        />
                    </Form.Field>

                    <Form.Field>
                        <label>Password</label>
                        <input
                            required
                            placeholder='Password'
                            value={password}
                            type='password'
                            onChange={e => setPassword(e.target.value)}
                        />
                    </Form.Field>

                    <Button fluid type='submit' loading={isLoading} disabled={isLoading}>Login</Button>
                </Form>

                {
                    errorMessage && (
                        <Message negative>
                            {errorMessage}
                        </Message>
                    )
                }


            </Grid.Column>
        </Grid>
    )
}

export default Login
