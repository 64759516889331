import React, { useEffect, useState } from 'react'
import { Button, Header, Table, Divider, Form, Modal, Dimmer, Loader, Dropdown, Pagination } from 'semantic-ui-react'
import ApiService from '../../../services/api/ApiService'
import DateUtils from '../../../utils/DateUtils'
import AddNoticeForm from './Add'

const Notice = () => {

    const [dataList, setDataList] = useState([])
    const [dataLoading, setDataLoading] = useState(true)
    const [deleting, setDeleting] = useState(false)

    const [addDataFormOpen, setAddDataFormOpen] = useState(false)
    const [editDataFormOpen, setEditDataFormOpen] = useState(false)

    const [editData, setEditData] = useState({})

    const [perPage, setPerPage] = useState(20)
    const [totalPage, setTotalPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [filterProgramList, setFilterProgramList] = useState([])
    const [selectedProgram, setSelectedProgram] = useState(null)

    useEffect(() => {
        getdataList()
        getFilterParams()
    }, [])

    useEffect(() => {
        getdataList()
    }, [currentPage, selectedProgram])

    const getdataList = () => {
        setDataLoading(true)

        let params = {
            paginate: true,
            perPage: perPage,
            currentPage: currentPage,
            program_id: selectedProgram
        }
        ApiService.sendRequest('notice/get-list', params).then(res => {
            setTotalPage(res.data.last_page)
            setDataList(res.data.data.map((item) => {
                let utcDate = item.created_at
                let localDate = new Date(utcDate)
                return {
                    ...item,
                    title: item.title.substring(0, 100),
                    text: item.text.substring(0, 200),
                    created_at: DateUtils.getDateTime(localDate)

                }
            }))
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDataLoading(false)
        })
    }

    const getFilterParams = () => {
        ApiService.sendRequest('base-program-list').then(res => {
            setFilterProgramList(res.data.map(item => {
                return {
                    key: item.id,
                    text: item.program_hierarchy_flat ? `${item.name} (${item.program_hierarchy_flat})` : item.name,
                    value: item.id
                }
            }))
        }).catch(err => {
        }).finally(() => {
        })
    }

    const DeleteButton = ({ data }) => {
        return <Modal
            size='tiny'
            trigger={<Button icon='trash' color='red' />}
            header={(<Header content='Confirm Delete?' />)}
            content={`Are you sure you want to delete ${data.title}`}
            actions={[
                {
                    key: 'delete', content: 'Delete', negative: true, onClick: () => deleteNotice(data.id), disabled: deleting,
                },
                'Cancel'
            ]}
        />
    }

    const deleteNotice = (id) => {
        let params = {
            'id': id
        }
        setDeleting(true)
        ApiService.sendRequest('notice/delete', params).then(res => {
            alert(res.message)
            getdataList()
        }).catch(err => {
            alert(err)
        }).finally(() => {
            setDeleting(false)
        })
    }

    return (
        <div className='page-container'>
            <div className='page-header'>
                <Header size='huge'>Notices</Header>
                <Divider />

                <Form>
                    <Form.Group inline>

                        <Pagination
                            width='16'
                            defaultActivePage={currentPage}
                            firstItem={null}
                            lastItem={null}
                            totalPages={totalPage}
                            onPageChange={(e, { activePage }) => setCurrentPage(activePage)}
                        />

                        <Form.Field width='16' style={{ marginLeft: 24 }}>
                            <label>Filter</label>

                            <Dropdown
                                style={{ width: 360 }}
                                value={selectedProgram}
                                selection
                                search
                                clearable
                                options={filterProgramList}
                                placeholder='Program'
                                onChange={(e, { value }) => setSelectedProgram(value)}
                            />
                        </Form.Field>


                        <Button
                            icon='add'
                            labelPosition='left'
                            content='Add'
                            primary
                            onClick={() => setAddDataFormOpen(true)}
                            disabled={dataLoading}
                            loading={dataLoading}
                        />
                    </Form.Group>
                </Form>
            </div>

            <Table compact='very' className='data-table'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>SN</Table.HeaderCell>
                        <Table.HeaderCell>Program</Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Message</Table.HeaderCell>
                        <Table.HeaderCell>Date/Time</Table.HeaderCell>
                        {/* <Table.HeaderCell>Image</Table.HeaderCell> */}
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        dataList.map((item, i) => (
                            <Table.Row key={i}>
                                <Table.Cell>{(currentPage - 1) * perPage + i + 1}</Table.Cell>
                                <Table.Cell>{item.program.code}</Table.Cell>
                                <Table.Cell>{item.title}</Table.Cell>
                                <Table.Cell width={6}>{item.text}</Table.Cell>
                                <Table.Cell>{item.created_at}</Table.Cell>
                                {/* <Table.Cell>{}</Table.Cell> */}
                                <Table.Cell>
                                    <Button.Group size='tiny'>
                                        <Button icon='edit' positive title='Edit' onClick={() => {
                                            setEditData(item)
                                            setEditDataFormOpen(true)
                                        }} />
                                        <DeleteButton data={item} title='Delete' />
                                    </Button.Group>
                                </Table.Cell>
                            </Table.Row>
                        ))
                    }

                </Table.Body>

            </Table>

            {
                dataLoading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )
            }

            <AddNoticeForm
                open={addDataFormOpen}
                onClose={() => {
                    setAddDataFormOpen(false)
                    getdataList()
                }}
            />

            <AddNoticeForm
                edit={true}
                defaultData={editData}
                open={editDataFormOpen}
                onClose={() => {
                    setEditDataFormOpen(false)
                    getdataList()
                }}
            />

        </div>
    )
}

export default Notice
