import React, { useState } from 'react'

export default function FileInput({
    name,
    image,
    onValueChange,
    ...other
}) {
    const [previewImage, setPreviewImage] = useState(image)

    const handleFileChange = (e) => {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return;

        let file = files[0]

        let reader = new FileReader();
        reader.onload = (e) => {
            setPreviewImage(e.target.result)
        }
        reader.readAsDataURL(file)
        onValueChange(name, file)
    }

    return (
        <div>
            <div style={styles.input}>
                <input id={name} type='file' onChange={handleFileChange} {...other} />
            </div>

            {
                previewImage && (
                    <img style={styles.previewImage} src={previewImage} draggable={false} alt='' />
                )
            }

        </div>
    )
}

const styles = {
    input: {
        position: 'relative'
    },
    previewImage: {
        maxWidth: 150
    }
}