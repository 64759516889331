class DateUtils {

    getTodayDate() {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        return `${year}-${month}-${day}`
    }

    formatDate(date) {
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        let day = date.getDate()

        return `${year}-${month}-${day}`
    }

    getWeekDayList() {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    }

    getWeekDay(weekday) {
        return this.getWeekDayList()[parseInt(weekday) - 1]
    }

    getDateTime(date) {
        let d = this.formatDate(date)
        let t = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
        return `${d} ${t}`
    }
}

export default new DateUtils()